import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import QSHEDetails from "../Segments/QsheDetails";

var bnrimg = require("./../../images/banner/2.jpg");

class ServicesOilgas extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <div className="section-full small-device  p-t80 p-b50 bg-white">
            <div className="section-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h3>Privacy Policy</h3>
                    <section>
                      <h4>1. Information We Collect</h4>
                      <p>
                        We collect various types of information to provide and
                        improve our services, including:
                      </p>
                      <ul>
                        <li>
                          <strong>Personal Information</strong>: This includes
                          details such as your name, email address, phone
                          number, and other contact details when you fill out a
                          contact form or communicate with us.
                        </li>
                        <li>
                          <strong>Usage Data</strong>: This includes information
                          on how you access and use the Website, such as IP
                          addresses, browser types, device information, and
                          pages visited.
                        </li>
                        <li>
                          <strong>Cookies and Tracking Technologies</strong>: We
                          may use cookies to enhance your experience on our
                          Website. Cookies are small text files that are stored
                          on your device to track your interactions with the
                          Website and improve functionality.
                        </li>
                      </ul>
                    </section>

                    <section>
                      <h4>2. How We Use Your Information</h4>
                      <p>
                        We use the collected information for various purposes,
                        including but not limited to:
                      </p>
                      <ul>
                        <li>Providing and maintaining our services.</li>
                        <li>
                          Responding to your inquiries and providing customer
                          support.
                        </li>
                        <li>
                          Monitoring and analyzing usage and trends to improve
                          our Website and services.
                        </li>
                        <li>
                          Communicating with you, including sending newsletters,
                          updates, and promotional offers, if you have opted in.
                        </li>
                        <li>
                          Enhancing security and preventing fraudulent
                          activities on our Website.
                        </li>
                      </ul>
                    </section>

                    <section>
                      <h4>3. Sharing Your Information</h4>
                      <p>
                        We do not sell or rent your personal information to
                        third parties. However, we may share your information in
                        the following situations:
                      </p>
                      <ul>
                        <li>
                          <strong>Service Providers</strong>: We may share your
                          information with trusted third-party service providers
                          who help us with website operations, data analytics,
                          and other services.
                        </li>
                        <li>
                          <strong>Legal Compliance</strong>: We may disclose
                          your information if required by law or in response to
                          legal requests, such as a court order or government
                          investigation.
                        </li>
                        <li>
                          <strong>Business Transfers</strong>: In the event of a
                          merger, acquisition, or sale of assets, your
                          information may be transferred as part of the
                          transaction.
                        </li>
                      </ul>
                    </section>

                    <section>
                      <h4>4. Data Security</h4>
                      <p>
                        We take reasonable precautions to protect your personal
                        data from unauthorized access, alteration, disclosure,
                        or destruction. However, no data transmission over the
                        internet is completely secure, and we cannot guarantee
                        the absolute security of your information.
                      </p>
                    </section>

                    <section>
                      <h4>5. Your Rights</h4>
                      <p>You have the right to:</p>
                      <ul>
                        <li>Access and update your personal information.</li>
                        <li>
                          Request the deletion of your personal data, subject to
                          legal obligations.
                        </li>
                        <li>
                          Opt out of receiving marketing communications from us
                          by following the unsubscribe instructions in our
                          emails.
                        </li>
                      </ul>
                    </section>

                    <section>
                      <h4>6. Cookies and Tracking Technologies</h4>
                      <p>
                        We use cookies and similar tracking technologies to
                        enhance your experience on the Website. These
                        technologies help us analyze traffic patterns, remember
                        user preferences, and provide a more personalized
                        browsing experience. You can adjust your browser
                        settings to refuse cookies, but this may impact your
                        ability to use some features of the Website.
                      </p>
                    </section>

                    <section>
                      <h4>7. Third-Party Links</h4>
                      <p>
                        Our Website may contain links to third-party websites or
                        services. We are not responsible for the privacy
                        practices or the content of these external sites. We
                        encourage you to review their privacy policies before
                        providing any personal information.
                      </p>
                    </section>

                    <section>
                      <h4>9. Changes to this Privacy Policy</h4>
                      <p>
                        We may update this Privacy Policy from time to time. Any
                        changes will be posted on this page, and the "Effective
                        Date" will be updated accordingly. We encourage you to
                        review this Privacy Policy periodically to stay informed
                        about how we are protecting your information.
                      </p>
                    </section>

                    <section>
                      <h4>10. Contact Us</h4>
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy, or if you wish to exercise your rights regarding
                        your personal information, please contact us at:
                      </p>
                      <p>
                        <strong>RENOM</strong>
                      </p>
                      <p>
                        Email:{" "}
                        <a
                          style={{ color: "#00A0A9" }}
                          href="mail:crm.renom.in"
                        >
                          crm.renom.in
                        </a>
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ServicesOilgas;
