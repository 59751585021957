import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home1 from "./Pages/Home1";
import Home2 from "./Pages/Home2";
import Home3 from "./Pages/Home3";
import Home4 from "./Pages/Home4";
import Home5 from "./Pages/Home5";
import Home6 from "./Pages/Home6";
import Home7 from "./Pages/Home7";
import Home8 from "./Pages/Home8";
import Home9 from "./Pages/Home9";
import Home10 from "./Pages/Home10";
import Home11 from "./Pages/Home11";
import Home12 from "./Pages/Home12";
import About1 from "./Pages/About";
import About2 from "./Pages/About2";
import Services1 from "./Pages/Services1";
import Services2 from "./Pages/Services2";
import ServicesAgricultural from "./Pages/ServicesAgricultural";
import ServicesAutomotive from "./Pages/ServicesAutomotive";
import ServicesChemical from "./Pages/ServicesChemical";
import ServicesCivil from "./Pages/ServicesCivil";
import ServicesMechanical from "./Pages/ServicesMechanical";
import Qshe from "./Pages/Qshe";
import Csr from "./Pages/Csr";
import Ims from "./Pages/Ims";
import Terms from "./Pages/terms";
import Privacy from "./Pages/privacy";
import ServicesPowerEnergy from "./Pages/ServicesPowerEnergy";
import ProjectGrid from "./Pages/ProjectGrid";
import ProjectMasonry from "./Pages/ProjectMasonry";
import ProjectCarousel from "./Pages/ProjectCarousel";
import Operations from "./Pages/Operations";
import AssetDigitalization from "./Pages/AssetDigitalization";
import Vas from "./Pages/Vas";
import Shop from "./Pages/Shop";
import ShopDetail from "./Pages/ShopDetail";
import ShoppingCart from "./Pages/ShoppingCart";
import Checkout from "./Pages/Checkout";
import Wishlist from "./Pages/Wishlist";
import BlogGrid from "./Pages/BlogGrid";
import BlogList from "./Pages/BlogList";
import BlogPost from "./Pages/BlogPost";
import PricingPlan from "./Pages/PricingPlan";
import IconFont from "./Pages/IconFont";
import Team from "./Pages/Team";
import TeamSingle from "./Pages/TeamSingle";
import Faq from "./Pages/Faq";
import Error403 from "./Pages/Error403";
import Error404 from "./Pages/Error404";
import Error405 from "./Pages/Error405";
import ContactUs from "./Pages/ContactUs";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Routes>
            <Route path="/" exact element={<Home1 />} />
            <Route path="/home-factory2" act element={<Home2 />} />
            <Route path="/home-construction3" act element={<Home3 />} />
            <Route path="/home-transport" act element={<Home4 />} />
            <Route path="/home-agriculture" act element={<Home5 />} />
            <Route path="/home-solar-energy" act element={<Home6 />} />
            <Route path="/home-oilgas-plant" act element={<Home7 />} />
            <Route path="/home-page8" act element={<Home8 />} />
            <Route path="/home-industries9" act element={<Home9 />} />
            <Route path="/home-factory10" act element={<Home10 />} />
            <Route path="/home-construction11" act element={<Home11 />} />
            <Route
              path="/home-conditioning-repair12"
              act
              element={<Home12 />}
            />

            <Route path="/about" act element={<About1 />} />
            {/* <Route path="/about-2" act element={<About2 />} /> */}

            <Route path="/services-1" act element={<Services1 />} />
            <Route path="/services-2" act element={<Services2 />} />
            <Route
              path="/services-agricultural"
              act
              element={<ServicesAgricultural />}
            />
            <Route
              path="/services-automotive"
              act
              element={<ServicesAutomotive />}
            />
            <Route
              path="/services-chemical"
              act
              element={<ServicesChemical />}
            />
            <Route path="/services-civil" act element={<ServicesCivil />} />
            <Route
              path="/services-mechanical"
              act
              element={<ServicesMechanical />}
            />
            <Route path="/qshe-management" act element={<Qshe />} />
            <Route path="/csr" act element={<Csr />} />
            <Route path="/ims" act element={<Ims />} />
            <Route
              path="/services-powerenergy"
              act
              element={<ServicesPowerEnergy />}
            />

            <Route path="/project-grid" act element={<ProjectGrid />} />
            <Route path="/project-masonry" act element={<ProjectMasonry />} />
            <Route path="/project-carousel" act element={<ProjectCarousel />} />
            <Route path="/operations" act element={<Operations />} />
            <Route
              path="/asset-digitalization"
              act
              element={<AssetDigitalization />}
            />
            <Route path="/vas" act element={<Vas />} />

            <Route path="/shop" act element={<Shop />} />
            <Route path="/shop-detail" act element={<ShopDetail />} />
            <Route path="/shopping-cart" act element={<ShoppingCart />} />
            <Route path="/checkout" act element={<Checkout />} />
            <Route path="/wishlist" act element={<Wishlist />} />

            <Route path="/blog-grid" act element={<BlogGrid />} />
            <Route path="/blog-list" act element={<BlogList />} />
            <Route path="/blog-post" act element={<BlogPost />} />

            <Route path="/pricing-plan" act element={<PricingPlan />} />
            <Route path="/icon-font" act element={<IconFont />} />
            <Route path="/team" act element={<Team />} />
            <Route path="/team-single" act element={<TeamSingle />} />
            <Route path="/faq" act element={<Faq />} />
            <Route path="/error403" act element={<Error403 />} />
            <Route path="/error404" act element={<Error404 />} />
            <Route path="/error405" act element={<Error405 />} />

            <Route path="/contactus" act element={<ContactUs />} />
            <Route path="/terms" act element={<Terms />} />
            <Route path="/privacy" act element={<Privacy />} />
            <Route element={Error} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
