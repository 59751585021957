import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import AssetDetails from "../Segments/AssetDetails";

var bnrimg = require("./../../images/banner/titlebg.jpg");

class AssetDigitalization extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          {/* INNER PAGE BANNER */}
          <Banner
            title="Asset digitalization and Optimization"
            pagename="Asset digitalization and Optimization"
            bgimage={bnrimg}
          />
          {/* INNER PAGE BANNER END */}
          {/* <Projects17 /> */}
          <AssetDetails />
        </div>
        <Footer />
      </>
    );
  }
}

export default AssetDigitalization;
