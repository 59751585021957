import React from "react";

class ProjectDetails extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50">
          <div className="container">
            <div className="row d-flex justify-content-center flex-wrap">
              <div className="col-lg-4 col-md-12 m-b30">
                {/* <div className="project-detail-left bg-gray p-a30">
                  <div className="wt-icon-box-wraper left  m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                    <div className="icon-sm m-b15">
                      <span className="icon-cell  site-text-primary">
                        <i className="fa fa-tachometer" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="wt-tilte m-b5">Project</h3>
                      <p>Refinery Industrial</p>
                    </div>
                  </div>
                  <div className="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                    <div className="icon-sm m-b15">
                      <span className="icon-cell  site-text-primary">
                        <i className="fa fa-list-alt" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="wt-tilte m-b5">Categories</h3>
                      <p>Business, Industrial</p>
                    </div>
                  </div>
                  <div className="wt-icon-box-wraper left  m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                    <div className="icon-sm m-b15">
                      <span className="icon-cell  site-text-primary">
                        <i className="fa fa-calendar" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="wt-tilte m-b5">Time</h3>
                      <p>2 Month</p>
                    </div>
                  </div>
                  <div className="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                    <div className="icon-sm m-b15">
                      <span className="icon-cell  site-text-primary">
                        <i className="fa fa-tags" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="wt-tilte m-b5">Status</h3>
                      <p>Good</p>
                    </div>
                  </div>
                  <div className="wt-icon-box-wraper left">
                    <div className="icon-sm m-b15">
                      <span className="icon-cell  site-text-primary">
                        <i className="fa fa-user" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="wt-tilte m-b5">Client</h3>
                      <p>Matthew Stone </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-12 col-md-12 m-b30">
                <div className="project-detail-right">
                  <h2 className="m-t0"> O & M practices</h2>
                  <p>
                    Considering the growing competition, every organization that
                    deals with renewable energy are looking for opportunities
                    that will help them improve their bottom line. RENOM
                    specializes in O & M contracts; contracts that cover all
                    aspects of your plant, contracts that will minimize your
                    risks and maximize profits. The predictive intelligence,
                    drawn from years of experience and research, will help you
                    increase productivity by detecting and diagnosing problems
                    ahead of time. Our team of experts will help your plant
                    operators to expand their span of control and will allow
                    them to run the processes in the most economical and
                    effective manner.
                  </p>
                  <p>
                    Management of O & M practices is critical for the success of
                    your plant. The RENOM team will ensure that all the
                    functions within your O & M service portfolio are bound
                    together to work as a cohesive unit. We will ensure that
                    while providing you with end-to-end service and support, we
                    will maintain the persistence of your business goals
                  </p>
                  <p>
                    At RENOM, we can tailor make O&M services that will help
                    customers translate their vision into reality; on schedule,
                    and within their budget. The depth of knowledge of our
                    engineering teams helps us resolve a greater range of
                    challenges, avoiding pitfalls, making sure your project
                    progresses seamlessly. An effort that fuels to meet
                    customer’s budgetary and sustainability goals while
                    providing work efficiency and reliability.
                  </p>
                  <h3 className="m-t0">RENOM offers customers</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse viverra mauris eget tortor imperdiet vehicula.
                    Proin egestas diam ac mauris molestie hendrerit.
                  </p>
                  <ul className="list-check-circle primary">
                    <li>
                      Comprehensive O&M contracts: End to end maintenance
                      including Spares for Break down maintenance & Preventive
                      Maintenance, consumables, Manpower charges, Technical
                      support, Insurance, Security deployment for the asset,
                      Vehicle for site operations
                    </li>
                    <li>
                      Semi- Comprehensive O&M contracts: Manpower, consumables,
                      Spares for Preventive Maintenance, Technical support
                    </li>
                    <li>
                      Non-Comprehensive O&M contracts: Technical Man power
                      services
                    </li>
                  </ul>
                  {/* <h3 className="m-t0">Problem</h3> */}
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse viverra mauris eget tortor imperdiet vehicula.
                    Proin egestas diam ac mauris molestie hendrerit.
                  </p>
                  <h3 className="m-t0">Solution</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse viverra mauris eget tortor imperdiet vehicula.
                    Proin egestas diam ac mauris molestie hendrerit.
                  </p>
                  <h3 className="m-t0">Process</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse viverra mauris eget tortor imperdiet vehicula.
                    Proin egestas diam ac mauris molestie hendrerit.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectDetails;
