import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const services = [
  {
    count: "01",
    title1: "OPERATIONS &",
    title2: "MAINTENANCE",
    flaticon: "flaticon-industry",
    description: (
      <ul
        style={{
          listStyle: "none",
          padding: 10,
          margin: 0,
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        <li style={{ marginBottom: 5 }}>Comprehensive O&M Services </li>
        <li style={{ marginBottom: 5 }}> Semi- Comprehensive O&M Service</li>
        <li style={{ marginBottom: 5 }}>Non- Comprehensive O&M Services</li>
        <li style={{ marginBottom: 40 }}>Technical Services</li>
      </ul>
    ),
  },
  {
    count: "02",
    title1: "Asset Digitisation &",
    title2: "Optimisation",
    flaticon: "flaticon-industry",
    description: (
      <ul
        style={{
          listStyle: "none",
          padding: 10,
          margin: 0,
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        <li style={{ marginBottom: 5 }}>
          RESCA- ONE SCADA Solution for Assets
        </li>

        <li style={{ marginBottom: 5 }}>
          Asset Management & Planning system for PM/BM Maintenance
        </li>
        <li style={{ marginBottom: 5 }}>
          DT - Digital Twin for Predictive Maintenance
        </li>
      </ul>
    ),
  },
  {
    count: "03",
    title1: "Value Added ",
    title2: "Services (VAS",
    flaticon: "flaticon-industry",
    description: (
      <ul
        style={{
          listStyle: "none",
          padding: 10,
          margin: 0,
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        <li style={{ marginBottom: 5 }}>
          Installing Vortex Generators to Blades
        </li>
        <li style={{ marginBottom: 5 }}> Semi- Comprehensive O&M Service</li>
        <li style={{ marginBottom: 5 }}>Leading edge protection of Blades</li>
        <li style={{ marginBottom: 5 }}>
          Blade Cleaning with IRATA certified Rope access technique
        </li>
      </ul>
    ),
  },
];

var bnr1 = require("./../../images/turbinfarmbg.jpg");

class Services1 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      nav: true,
      dots: false,
      margin: 10,
      navText: [
        '<i class="fa fa-long-arrow-left"></i>',
        '<i class="fa fa-long-arrow-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        640: {
          items: 1,
        },
        767: {
          items: 2,
        },
        991: {
          items: 2,
        },
        1366: {
          items: 2,
        },
        1400: {
          items: 3,
        },
      },
    };

    return (
      <>
        <div
          className="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="overlay-main site-bg-secondry opacity-08" />
          <div className="section-content services-section-content">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12">
                <div className="services-section-content-left">
                  {/* TITLE START*/}
                  <div className="left wt-small-separator-outer text-white">
                    <div className="wt-small-separator site-text-primary">
                      <div className="sep-leaf-left" />
                      <div>The Best in Class services</div>
                      <div className="sep-leaf-right" />
                    </div>
                    <h2>One Team, One Goal, Customer First</h2>
                    <p>
                      We are committed to comply, periodically review, and
                      continuously improve the effectiveness of the quality
                      management system we practice. The aim is to be the most
                      preferred ISP in the energy sector.
                    </p>
                    <NavLink
                      to="/contactus"
                      className="site-button site-btn-effect"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                  {/* TITLE END*/}
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12">
                <div className="services-section-content-right">
                  <OwlCarousel
                    className="owl-carousel services-slider owl-btn-vertical-center"
                    {...options}
                  >
                    {services.map((item, index) => (
                      <div key={index} className="item">
                        <div
                          className="wt-box service-box-1 bg-white"
                          style={{ minHeight: "490px" }}
                        >
                          <div className="service-box-title title-style-2 site-text-secondry">
                            <span className="s-title-one">{item.title1}</span>
                            <span className="s-title-two">{item.title2}</span>
                          </div>
                          <div className="service-box-content">
                            <div>{item.description}</div>
                            <NavLink to="/about-1" className="site-button-link">
                              Read More
                            </NavLink>
                          </div>
                          <div className="wt-icon-box-wraper">
                            <div className="wt-icon-box-md site-bg-primary">
                              <span className="icon-cell text-white">
                                <i className={item.flaticon} />
                              </span>
                            </div>
                            <div className="wt-icon-number">
                              <span>{item.count}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services1;
