import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Segments/Switcher";

var bgimage = require("./../../images/background/footer-bg.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);

    var homepage4 = /\/home-transport/i;
    var homepage5 = /\/home-agriculture/i;

    if (homepage4.test(window.location.href)) {
      this.currentstate = { logo: require("./../../images/logo.png") };
    } else if (homepage5.test(window.location.href)) {
      this.currentstate = { logo: require("./../../images/logo.png") };
    } else {
      this.currentstate = { logo: require("./../../images/logo.png") };
    }
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo.default;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large footer-dark text-white footer-style1">
          {/* FOOTER BLOCKES START */}
          <div
            className="footer-top bg-no-repeat bg-bottom-right"
            style={{ backgroundImage: "url(" + bgimage.default + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="footer-h-left">
                    <div className="widget widget_about">
                      <div className="logo-footer clearfix">
                        <NavLink to="/">
                          <img
                            src={this.currentstate.logo}
                            alt=""
                            style={{ width: "200px" }}
                          />
                        </NavLink>
                      </div>
                      <p>
                        RENOM strives to be the preferred Independent Service
                        Provider (ISP) in the global renewable energy market,
                        delivering premium, cost-effective services for 0.225MW
                        to 2MW turbines across India. We believe every asset
                        owner has the right to understand their assets and make
                        informed decisions. Backed by over 1,500 man-years of
                        expertise across various technologies and models, RENOM
                        offers unmatched, comprehensive services nationwide.
                      </p>
                    </div>
                    <div className="widget recent-posts-entry">
                      <ul className="widget_address">
                        <li>RENOM ENERGY SERVICES Pvt. Ltd.</li>
                        <li>
                          <i className="fa fa-map-marker" />
                          Anand square 302 & 303 , b wing , 3rd floor
                        </li>
                        <li>
                          Opposite symbiosis international ( deemed university )
                        </li>
                        <li>Vimannagar Pune - 411014</li>
                        <li>
                          <i className="fa fa-envelope" />
                          info@renom.in
                        </li>
                        <li>
                          {" "}
                          <i className="fa fa-phone" />
                          +91 8446341555
                        </li>
                      </ul>
                    </div>
                    <ul className="social-icons  wt-social-links footer-social-icon">
                      <li>
                        <NavLink
                          to={"https://www.facebook.com/renomrenew/"}
                          className="fa fa-facebook"
                          target="_blank"
                        />
                      </li>
                      <li>
                        <NavLink
                          to={"https://x.com/renomenergy?lang=en"}
                          className="fa fa-twitter"
                          target="_blank"
                        />
                      </li>
                      <li>
                        <NavLink
                          to={
                            "https://www.linkedin.com/company/renom-energy-services-llp/about/"
                          }
                          className="fa fa-linkedin"
                          target="_blank"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="row footer-h-right">
                    <div className="col-lg-5 col-md-4">
                      <div className="widget widget_services">
                        <h3 className="widget-title">Useful links</h3>
                        <ul>
                          <li>
                            <NavLink to={"/"}>Home</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/qshe-management"}>
                              QSHE Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/asset-digitalization"}>
                              Asset Digitalization
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to={"/csr"}>CSR</NavLink>
                          </li> */}
                          <li>
                            <NavLink to={"/ims"}>IMS Policy </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                      <div className="widget widget_services">
                        <h3 className="widget-title">Our Services</h3>
                        <ul>
                          <li>
                            <NavLink to={"/"}>
                              Electronic Repair Station
                            </NavLink>
                            <NavLink to={"/"}>Special Task Activities</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/"}>Blade Care Services</NavLink>
                            <NavLink to={"/"}>
                              Operation and Maintenance
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/"}>Asset Digitalization</NavLink>
                            <NavLink to={"/"}>Value added Services</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="widget widget_newsletter">
                    <h3 className="widget-title">Newsletter</h3>
                    <p>
                      Subscribe to our newsletter to receive latest news on our
                      services.
                    </p>
                    <div className="newsletter-input">
                      <div className="input-group">
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text nl-search-btn text-black site-bg-primary title-style-2"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom">
            <div className="container">
              <div className="wt-footer-bot-left d-flex justify-content-between">
                <span className="copyrights-text">
                  Copyright © 2024 Renom Energy Services Pvt. Ltd.
                </span>
                <ul className="copyrights-nav">
                  <li>
                    <NavLink to={"/terms"}>Terms and Condition</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/privacy"}>Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/contactus"}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
