import React from "react";

class WhatWeDo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b70 bg-gray what-we-do-section">
          <div className="container">
            <div className="section-content what-we-do-content">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="whatWedo-media-section">
                    <div
                      className="wt-media d-flex align-items-center justify-content-center"
                      style={{ minHeight: "500px" }}
                    >
                      <img
                        src={require("./../../images/building.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="whatWedo-media-content text-white">
                      <div className="whatWedo-media-inner">
                        <h3> Working Dedicatedly</h3>
                        <p>
                          We have 9+ years of experience with providing wide
                          area of specialty services in energy sector
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="whatWedo-info-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>Our Philosophy</div>
                        <div className="sep-leaf-right" />
                      </div>
                      <h2>
                        Grow to be the biggest, the best and the most valuable.
                      </h2>
                      <p>
                        This simple philosophy has enabled us to treat each
                        success, each achievement as a milestone and has spurred
                        it to redouble its efforts to go from strength to
                        strength by raising the bar to the next level.
                      </p>
                    </div>
                    {/* TITLE END*/}
                    {/* <div className="wt-icon-card-outer">
                      <div className="wt-icon-card bg-white shadow">
                        <div className="wt-card-header">
                          <i className="flaticon-robotic-arm site-text-primary" />
                          <span className="title-style-2 site-text-secondry">
                            A Full Services
                          </span>
                        </div>
                        <div className="wt-card-content">
                          <p>
                            We are Providing different services in this sector
                            to wide area of world
                          </p>
                        </div>
                      </div>
                      <div className="wt-icon-card bg-white shadow">
                        <div className="wt-card-header">
                          <i className="flaticon-repair site-text-primary" />
                          <span className="title-style-2 site-text-secondry">
                            All Maintenance
                          </span>
                        </div>
                        <div className="wt-card-content">
                          <p>
                            We are Prous to Protect your organization with our
                            award-winning products
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-large-title title-style-2">
            <span>Philosophy</span>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo1;
