import React from "react";
import { NavLink } from "react-router-dom";

class About5 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-tb80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="img-colarge-new">
                    <div className="colarge-1-new">
                      <img
                        src={require("./../../images/turbinfarmbg.jpg")}
                        alt=""
                        className="slide-righ"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="welcom-to-section-new">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        {/* <div className="sep-leaf-left" />
                        <div>Welcome to industro</div>
                        <div className="sep-leaf-right" /> */}
                      </div>
                    </div>
                    <h2>Our Values</h2>
                    {/* TITLE END*/}
                    <ul className="site-list-style-one-new">
                      <li>
                        Treat people – Employees, Vendors, Contractors as part
                        of our extended family and work incessantly towards
                        their welfare.
                      </li>
                      <li>
                        Follow fair trade practices and the utmost transparency
                        in all business dealings.
                      </li>
                      <li>
                        Be a socially responsible corporate citizen by paying
                        back to the society what we earn from it by contributing
                        generously to social, medical and educational causes to
                        help the down-trodden.
                      </li>
                      <li>Contribute to the development of the country.</li>
                      <li>
                        Work ceaselessly for the protection of the environment.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About5;
