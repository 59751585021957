import React from "react";
import { NavLink } from "react-router-dom";

const products = [
  {
    image: require("./../../images/assetdigitalization/integrated_view.png"),
    title: "Integrated View",
    price: "49.00",
  },
  {
    image: require("./../../images/assetdigitalization/automate.png"),
    title: "Automate Data",
    price: "99.00",
  },
  {
    image: require("./../../images/assetdigitalization/remote.png"),
    title: "Remote Diagnostics",
    price: "99.00",
  },
  {
    image: require("./../../images/assetdigitalization/monitor.png"),
    title: "Remote Monitoring",
    price: "199.00",
  },
  {
    image: require("./../../images/assetdigitalization/tech.png"),
    title: "Integrated View",
    price: "139.00",
  },
  {
    image: require("./../../images/assetdigitalization/scada.png"),
    title: "SCADA",
    price: "26.00",
  },
  {
    image: require("./../../images/assetdigitalization/scalable.png"),
    title: "Scalable",
    price: "86.00",
  },
  {
    image: require("./../../images/assetdigitalization/cloud.png"),
    title: "Cloud",
    price: "56.00",
  },
  {
    image: require("./../../images/assetdigitalization/vendor.png"),
    title: "Vendor Agnostic",
    price: "99.00",
  },
  {
    image: require("./../../images/assetdigitalization/achive.png"),
    title: "Realistically achievable",
    price: "99.00",
  },
];

class AssetDigitalizationFeatures extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          {products.map((item, index) => (
            <div key={index} className="col-lg-2 col-md-2 m-b30">
              <div className="wt-box wt-product-box block-shadow  overflow-hide">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <img
                    src={item.image}
                    alt=""
                    style={{ width: "70px", height: "70px" }}
                  />
                  {/* <div className="overlay-bx">
                    <div className="overlay-icon">
                      <NavLink to={"#"}>
                        <i className="fa fa-cart-plus wt-icon-box-xs" />
                      </NavLink>
                      <NavLink to={"#"} className="mfp-link">
                        <i className="fa fa-heart wt-icon-box-xs" />
                      </NavLink>
                    </div>
                  </div> */}
                </div>
                <div className="wt-info  text-center">
                  <div className="p-a20 bg-white">
                    <h5 className="wt-title">{item.title}</h5>
                    {/* <span className="price">
                      <ins>
                        <span>
                          <span className="Price-currencySymbol">$ </span>
                          {item.price}
                        </span>
                      </ins>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default AssetDigitalizationFeatures;
