import React from "react";
import { NavLink } from "react-router-dom";

class Vas extends React.Component {
  render() {
    return (
      <>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-t80 p-b50">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="img-colarge2">
                    {/* <div className="colarge-2 slide-right">
                      <img
                        src={require("./../../images/turbines.jpg")}
                        alt=""
                      />
                    </div> */}
                    <div className="colarge-2-1">
                      <img
                        src={require("./../../images/vas/windmill.jpg")}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                    {/* <div className="since-year-outer2">
                      <div className="since-year2">
                        <span>Since</span>
                        <strong>2015</strong>
                      </div>
                    </div> */}
                  </div>
                </div>{" "}
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="welcom-to-section">
                    {/* TITLE START*/}
                    {/* <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>One Team, One Gasdasdoal, Customer First</div>
                        <div className="sep-leaf-right" />
                      </div>
                    </div> */}
                    <h2>Enhancing efficiency by Optimized Aero dynamics</h2>
                    {/* TITLE END*/}

                    <p style={{ lineHeight: "1.7" }}>
                      Due to shape and operation limitations, the blades of
                      large pitch-regulated wind turbines often have suboptimal
                      aerodynamic properties at the root. This frequently leads
                      to an undesired airflow separation, known as aerodynamic
                      stall and can have a significant negative impact on wind
                      turbine efficiency. Surface roughness and leading edge
                      erosion can only increase the problem
                    </p>
                    <p style={{ lineHeight: "1.7" }}>
                      With 3M, We have signed an agreement to install Vortex
                      Generators (VG’s) on WTGs in India. This collaboration
                      will leverage the materials expertise and technology of 3M
                      combined with the skills & competence of RENOM to provide
                      asset owners a solution to increase efficiency and
                      maximize Annual Energy Production to 1% or more from the
                      wind turbines.
                    </p>
                    <p>
                      3M Wind Vortex Generators are attached to the root section
                      of a wind turbine blade on spots that are determined using
                      a proprietary analysis process. They help improve
                      performance by energizing flow around the surface. This
                      helps reduce flow separation and increases the performance
                      of the entire turbine, in terms of power, loads and
                      service life.
                    </p>
                    {/* <div className="welcom-to-section-bottom d-flex justify-content-between">
                      <div className="welcom-btn-position">
                        <NavLink
                          to={"/about-1"}
                          className="site-button-secondry site-btn-effect"
                        >
                          More About
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-tb80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="welcom-to-section-new">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer"></div>
                    <h2>Increased efficiency</h2>
                    <p>
                      Increase of Annual energy production typically in the
                      range of 1.5 to 3 % has been confirmed in different case
                      studies, however this varies depends on turbine type ,
                      turbine conditions & site conditions . Stabilized
                      aerodynamics help balance load effects of unsteady wind
                      conditions Reduced noise due to less stall
                    </p>
                    <h2>Easy to Install and Faster Pay-back</h2>
                    <p>
                      Designed for higher reliability and fast roll out to wind
                      parks Qualified installation specialists Adaptable to
                      different blade types independent of turbine OEM Proven in
                      hundreds of existing installations and backed by case
                      studies about actual performance improvement Payback time
                      of approximately 2 to 2.5 years including installation,
                      materials and downtime
                    </p>
                    <p>
                      <p>
                        3M Wind Vortex Generators are attached to the root
                        section of a wind turbine blade on spots that are
                        determined using a proprietary analysis process. They
                        help improve performance by energizing flow around the
                        surface. This helps reduce flow separation and increases
                        the performance of the entire turbine, in terms of
                        power, loads and service life.
                      </p>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="img-colarge-new">
                    <div className="">
                      <img
                        src={require("./../../images/vas/graph.jpg")}
                        alt=""
                        className="slide-righ"
                      />
                      <img
                        src={require("./../../images/vas/part.jpg")}
                        alt=""
                        className="slide-righ"
                        style={{ width: "100%", marginTop: "50px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Vas;
