import React from "react";
import { NavLink } from "react-router-dom";

class About1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-tb80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="img-colarge">
                    <div className="colarge-1">
                      <img
                        src={require("./../../images/turbines.jpg")}
                        alt=""
                        className="slide-righ"
                      />
                    </div>
                    <div className="since-year-outer">
                      <div className="since-year">
                        <span>Since</span>
                        <strong>2015</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="welcom-to-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>About Renom</div>
                        <div className="sep-leaf-right" />
                      </div>
                    </div>
                    <h3>
                      Managing over 1000 MW of assets, a strong presence across
                      India’s renewable energy landscape.
                    </h3>
                    {/* TITLE END*/}
                    <ul className="site-list-style-one">
                      <li>
                        Comprehensive services for 0.225 MW to 2 MW turbines of
                        various makes and models, across India.
                      </li>
                      <li>Over 1500 years of combined industry experience.</li>
                      <li>
                        Advanced SCADA with a single-window dashboard for remote
                        monitoring and control.
                      </li>
                      <li>
                        In-house Electronic Repair Station (ERS) for PCB,
                        Controller, and SRB repairs.
                      </li>
                      <li>
                        Machine Refurbishment Facility (MRF) and Crane-less
                        maintenance capabilities.
                      </li>
                      <li>Expert rotor blade repair services.</li>
                      <li>
                        Partnerships with insurance companies for risk
                        management and advisory.
                      </li>
                    </ul>

                    <div className="welcom-to-section-bottom d-flex justify-content-between">
                      <div className="welcom-btn-position">
                        <NavLink
                          to={"/about-1"}
                          className="site-button-secondry site-btn-effect"
                        >
                          Read More
                        </NavLink>
                      </div>
                      {/* <div className="welcom-sign-pic">
                        <img src={require("./../../images/sign.png")} alt="" />
                      </div> */}
                      <div className="welcom-sign-info">
                        <strong>Mr. Lakshmanan .S</strong>
                        <span>( CEO )</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About1;
