import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const logos = [
  { image: require("./../../images/clientlogos/Acciona-200x139.png") },
  { image: require("./../../images/clientlogos/apprava-Logo-200x71.jpg") },
  { image: require("./../../images/clientlogos/AttriaPower.jpg") },
  { image: require("./../../images/clientlogos/bharatforge-200x50.png") },
  { image: require("./../../images/clientlogos/Clover-Builders-200x62.png") },
  { image: require("./../../images/clientlogos/Emerson-200x108.png") },
  { image: require("./../../images/clientlogos/Enviro-Power-200x96.png") },
  { image: require("./../../images/clientlogos/ge-200x109.png") },
  { image: require("./../../images/clientlogos/Ghatge-Patil-200x84.png") },
  { image: require("./../../images/clientlogos/HeroFutureEnergy-200x112.jpg") },
  { image: require("./../../images/clientlogos/hodawat.png") },
  { image: require("./../../images/clientlogos/Kirloskar-200x114.png") },
  { image: require("./../../images/clientlogos/leapgreenenrgey-200x200.jpg") },
  { image: require("./../../images/clientlogos/MSPL-Ltd-200x105.png") },
  { image: require("./../../images/clientlogos/Mysore-Mercantile-200x32.png") },
  { image: require("./../../images/clientlogos/Pioneer-Wincon.png") },
  {
    image: require("./../../images/clientlogos/tatapowerrenewables-200x118.jpg"),
  },
  { image: require("./../../images/clientlogos/vtst.png") },
  { image: require("./../../images/clientlogos/zr-logo-200x68.jpg") },
];

class ClientsLogo1 extends React.Component {
  render() {
    const options = {
      loop: true,
      nav: false,
      dots: true,
      margin: 10,
      autoplay: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 2,
        },
        480: {
          items: 3,
        },
        767: {
          items: 4,
        },
        1000: {
          items: 6,
        },
      },
    };
    return (
      <>
        <div className="section-full bg-white">
          <div className="">
            <div className="section-content">
              <div className="section-content">
                <div className="section-content p-tb30 owl-btn-vertical-center">
                  <h2 className="text-center p-b20">Our Clients</h2>
                  <OwlCarousel
                    className="owl-carousel home-client-carousel-2"
                    {...options}
                  >
                    {logos.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="ow-client-logo">
                          <div className="client-logo client-logo-media d-flex align-items-center justify-content-center">
                            <NavLink to={"#"}>
                              <img
                                src={item.image}
                                alt=""
                                style={{
                                  maxWidth: "150px",
                                  maxHeight: "80px",
                                  display: "block",
                                  margin: "0 auto",
                                }}
                              />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
