import React from "react";
import assetImage from "../../images/assetdigitalization/mainbanner.jpg";
import AssetDigitalizationFeatures from "./AssetDigitalizationFeatures";
import archImage from "../../images/assetdigitalization/arch.jpg";
import realtimeImage from "../../images/assetdigitalization/realtime.jpg";
import analyticsImage from "../../images/assetdigitalization/analytics.jpg";

class AssetDetails extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50">
          <div className="container">
            <div className="row d-flex justify-content-center flex-wrap">
              <div className="col-lg-12 col-md-12 m-b30">
                <div className="project-detail-right">
                  <h2 className="m-t0">
                    {" "}
                    Asset digitalization and Optimization
                  </h2>
                  <p>
                    At RENOM, we have taken every effort to benchmark the global
                    industry trends and the future needs of the customers. There
                    is a constant effort on improving the service standards to
                    enhance the turbines reliability, performance and
                    sustainability. With digitization, RENOM is able to better
                    leverage analytics to improve operational efficiency and
                    predictive maintenance. The digitization journey of RENOM
                    has evolved from the yesterday’s SCADA until the tomorrow’s
                    world of Digital Twin with Augmented and Virtual reality
                  </p>

                  <img src={assetImage}></img>
                  <h2 className="m-t30">
                    SUPERVISORY CONTROL AND DATA ACQUISITION (SCADA)
                  </h2>
                  {/* TABS CONTENT START */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="wt-tabs border bg-tabs">
                        <ul className="nav nav-tabs">
                          <li>
                            <a
                              data-toggle="tab"
                              href="#realtimeTab"
                              className="active"
                            >
                              Real Time
                            </a>
                          </li>

                          <li>
                            <a data-toggle="tab" href="#analyticsTabs">
                              Data Analytics
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#archTab">
                              Architecture
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div id="realtimeTab" className="tab-pane active">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={realtimeImage}
                                style={{ width: "100%", marginBottom: "50px" }}
                              ></img>
                            </div>
                            <ul className="site-list-style-two m-b0">
                              <li>
                                Near Real time data analytics with Best in Class
                                Analytics tool
                              </li>
                              <li>
                                40+ KPIs representation at Turbine & Windfarm
                                levels for different Turbine makes on 1
                                dashboard
                              </li>
                              <li>
                                Graphical representation of KPIs for effective
                                operations management
                              </li>
                              <li>
                                Real time views of Power curves, Daily
                                Generation reports, PLF% etc.
                              </li>
                              <li>
                                Slice and Dice capability zoom in on specific
                                KPIs for Cause and Effect analysis
                              </li>
                              <li>
                                Error and Event logs with parameter values
                                integrated
                              </li>
                              <li>
                                Historical data availability for further
                                analytics
                              </li>
                            </ul>
                          </div>

                          <div id="analyticsTabs" className="tab-pane">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={analyticsImage}
                                style={{ width: "100%", marginBottom: "50px" }}
                              ></img>
                            </div>
                            <ul className="site-list-style-two m-b0">
                              <li>
                                Real time interface with Turbines / Windfarm
                              </li>
                              <li>
                                Best in class UX solution with high performance
                              </li>
                              <li>
                                Remote controlling options for Turbine
                                Start/Stop/ Reset with security layers
                              </li>

                              <li>
                                Error and Event logs with parameter values
                                integrated
                              </li>
                              <li>Admin functions & Reporting</li>
                            </ul>
                          </div>
                          <div id="archTab" className="tab-pane">
                            <div className="p-a10">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={archImage}
                                  style={{ width: "50%", marginBottom: "50px" }}
                                ></img>
                              </div>
                              <ul className="site-list-style-two m-b0">
                                <li>
                                  Cloud based solution to eliminate Capital IT
                                  Infrastructure cost and make Implementation &
                                  Data Integration much faster
                                </li>
                                <li>
                                  Vendor agnostic SCADA system on standard
                                  protocol with no dependency on OEMs
                                </li>
                                <li>
                                  Best in class technologies used to build
                                  solution
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TABS CONTENT START */}
                </div>
              </div>
              <AssetDigitalizationFeatures />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssetDetails;
