import React from "react";

var bnr1 = require("./../../images/background/bg-map.png");

class SelfIntro1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full self-intro-section-outer overlay-wraper"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="overlay-main site-bg-secondry opacity-09" />
          <div className="self-intro-top bg-gray p-t80 p-b50">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-6 col-md-12">
                  {/* TITLE START*/}
                  <div className="left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div className="sep-leaf-left" />
                      <div>Our Vision</div>
                      <div className="sep-leaf-right" />
                    </div>
                    <h3>
                      To be the most preferred Independent Service Provider
                      (ISP) for the customers in the global renewable energy
                      market space
                    </h3>
                  </div>
                  {/* TITLE END*/}
                </div>
              </div>
            </div>
          </div>
          <div className="self-intro-bottom p-t80 p-b80">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-6 col-md-6">
                  <div className="self-info-detail">
                    <div className="wt-icon-box-wraper p-b10 left">
                      <div className="icon-content text-white">
                        <h3 className="wt-tilte">Our Misson </h3>
                        <h2>
                          Improving Asset efficiency with effective maintenance
                          for sustainable growth.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="self-intro-pic-block">
              <div className="wt-media">
                <img
                  src={require("./../../images/builder_vertical.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="section-full p-t80 p-b50 bg-gray-light">
            <div className="container">
              {/* TITLE START*/}
              <div className="section-head center wt-small-separator-outer text-center">
                <div className="wt-small-separator site-text-primary">
                  <div className="sep-leaf-left" />
                  <div>Our Philosophy</div>
                  <div className="sep-leaf-right" />
                </div>
                <h2>Grow to be the biggest, the best and the most valuable.</h2>
                <p>
                  This simple philosophy has enabled us to treat each success,
                  each achievement as a milestone and has spurred it to redouble
                  its efforts to go from strength to strength by raising the bar
                  to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SelfIntro1;
