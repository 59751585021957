import React from "react";
import ServicesSidebar from "./ServicesSidebar";

class ServiceDetails6 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full small-device  p-t80 p-b50 bg-white">
          <div className="section-content">
            <div className="container">
              <div className="row">
                <ServicesSidebar />
                <div className="col-lg-8 col-md-12">
                  <div className="section-content">
                    {/* TITLE START */}
                    <div className="text-left">
                      <h2 className="wt-title">QHSE Management</h2>
                    </div>
                    {/* TITLE END */}
                    <div className="wt-box services-etc m-b30">
                      <p>
                        RENOM promotes and controls a safe and healthy working
                        environment, excellent work standards and environmental
                        protection for all of our company’s employees. RENOM
                        QHSE (Quality, Health, Safety, and Environment) is an
                        initiative that establishes a company-wide practice of
                        setting up and maintaining the highest QHSE standards
                        with a dedicated approach and organizational commitment.
                      </p>
                      <p>
                        {" "}
                        RENOM’s QHSE initiatives are intended to keep its
                        employees safe while making sure that its customers are
                        assured of their assets are maintained as per the
                        quality standards and a clear focus on attaining HSE
                        excellence. All the stakeholders including employees and
                        customers are assured of complete adherence to the
                        industry standards and laws of safety thus laying the
                        foundation for a successful and incident-free workplace.
                      </p>
                      <p>
                        {" "}
                        The HSE management strategy works towards the attainment
                        of the vision of the organization to assure complete
                        safety of its various stakeholders without compromising
                        on its principles of sustainability. Safety is a key
                        indicator of our success. Our QHSE department monitors
                        incidents, unsafe conditions, Unsafe acts and using the
                        lessons learned distributes that information to our
                        company’s employees to ensure that they do not reoccur.
                      </p>
                      <p>
                        {" "}
                        Utilizing a modern IT system where our Policy and
                        Procedure documents. Audits, Incident Reports,
                        NonConformities, and Corrective Actions are all stored
                        together will help you in this task. RENOM has an
                        efficient QHSE system that proactively minimizes risks
                        and helps to maintain regulatory compliance and a cycle
                        of continuous improvement.
                      </p>
                      {/* <div className="wt-media m-b30">
                        <img
                          src={require("./../../images/services/large/pic1.jpg")}
                          alt=""
                        />
                      </div> */}
                      {/* <div className="wt-info">
                        <blockquote className="m-a0 bg-gray p-a30">
                          <i className="fa fa-quote-left font-20" />
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                            ipsum dolor sit amet, consectetur adipisicing elit,
                          </p>
                         
                        </blockquote>
                      </div> */}
                    </div>
                    {/* <div className="service-provide">
                      <h2>System Benefits</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam. Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit.{" "}
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-angle-right">
                            <li>
                              Pitifully thin compared with the size of the rest
                              of him.
                            </li>
                            <li>
                              Raising a heavy muff that covered the whole. muff
                              that covered the whole of her lower arm
                              consectetur.
                            </li>
                            <li>
                              Muff that covered the whole of her lower arm
                              consectetur adipisicing elit
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <div className="wt-media m-b30">
                            <img
                              src={require("./../../images/gallery/pic3.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2>Research</h2>
                      <div className="wt-accordion acc-bg-gray" id="accordion5">
                        <div className="panel wt-panel">
                          <div className="acod-head acc-actives">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseOne5"
                                data-parent="#accordion5"
                              >
                                What problems we face?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseOne5"
                            className="acod-body collapse show bg-gray"
                          >
                            <div className="acod-content p-a15">
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, injected humour, or
                              randomised words which don't look even slightly
                              believablThere are many variations of passages.
                            </div>
                          </div>
                        </div>
                        <div className="panel wt-panel">
                          <div className="acod-head">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseTwo5"
                                className="collapsed"
                                data-parent="#accordion5"
                              >
                                Was our solution best?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseTwo5"
                            className="acod-body collapse bg-gray"
                          >
                            <div className="acod-content p-a15">
                              Graphic design lorem Ipsum is simply dummy text of
                              the printing and type setting industry. Lorem
                              Ipsum has been the industry's standard dummy text
                              ever since the when an unknown printer took.
                            </div>
                          </div>
                        </div>
                        <div className="panel wt-panel">
                          <div className="acod-head">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseThree5"
                                className="collapsed"
                                data-parent="#accordion5"
                              >
                                Was our client satisfy?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseThree5"
                            className="acod-body collapse bg-gray"
                          >
                            <div className="acod-content p-a15">
                              Developement lorem Ipsum is simply dummy text of
                              the printing and type has been the industry's
                              standard dummy text ever since the when an unknown
                              printer took a galley of type and scrambled it to
                              make.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceDetails6;
