import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import CSRDetails from "../Segments/CsrDetails";

var bnrimg = require("./../../images/banner/2.jpg");

class ServicesOilgas extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          {/* INNER PAGE BANNER */}
          {/* <Banner
            title="Service Detail"
            pagename="Service Detail"
            bgimage={bnrimg}
          /> */}
          {/* INNER PAGE BANNER END */}
          <CSRDetails />
        </div>
        <Footer />
      </>
    );
  }
}

export default ServicesOilgas;
