import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import Projects17 from "../Segments/Projects17";
import OperationDetails from "../Segments/OperationDetails";

var bnrimg = require("./../../images/banner/titlebg.jpg");

class Operation extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          {/* INNER PAGE BANNER */}
          <Banner
            title="Operation and Maintenance"
            pagename="Operation and Maintenance"
            bgimage={bnrimg}
          />
          {/* INNER PAGE BANNER END */}
          {/* <Projects17 /> */}
          <OperationDetails />
        </div>
        <Footer />
      </>
    );
  }
}

export default Operation;
