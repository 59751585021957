import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import QSHEDetails from "../Segments/QsheDetails";

var bnrimg = require("./../../images/banner/2.jpg");

class ServicesOilgas extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <div className="section-full small-device  p-t80 p-b50 bg-white">
            <div className="section-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h3>Terms and Conditions</h3>
                    <p>
                      Welcome to RENOM! Please read these terms and conditions
                      (“Terms”) carefully before using our website (“Website”).
                      By accessing or using the Website, you agree to comply
                      with and be bound by these Terms. If you do not agree with
                      these Terms, please refrain from using the Website.
                    </p>
                    <p>
                      RENOM, established in September 2015, is a leading
                      Independent Service Provider (ISP) in the renewable energy
                      sector. We specialize in premium, cost-effective services
                      for 0.225 MW to 2 MW turbines across India. With over
                      1,500 man-years of expertise, we offer innovative
                      solutions such as SCADA systems for remote monitoring and
                      control, an advanced Electronic Repair Station (ERS), and
                      machine refurbishment facilities. For more information,
                      visit our About section.
                    </p>
                    <ul>
                      <li>
                        The content on this Website is for informational
                        purposes only and should not be considered as
                        professional advice.
                      </li>
                      <li>
                        Unauthorized use of this Website may give rise to a
                        claim for damages and/or be a criminal offense.
                      </li>
                      <li>
                        You may not reproduce, distribute, or modify any content
                        from this Website without prior written consent from
                        RENOM.
                      </li>
                    </ul>
                    <h4>Services Offered</h4>
                    <p>
                      RENOM provides a comprehensive suite of services,
                      including but not limited to:
                    </p>
                    <ul>
                      <li>
                        Turbine Services: Maintenance and management for
                        turbines ranging from 0.225 MW to 2 MW across various
                        models.
                      </li>
                      <li>
                        Monitoring and Control: Advanced SCADA systems featuring
                        a single-window dashboard.
                      </li>
                      <li>
                        Repair and Refurbishment: In-house Electronic Repair
                        Station (ERS) for PCB, Controller, and SRB repairs;
                        Machine Refurbishment Facility (MRF); and crane-less
                        maintenance capabilities.
                      </li>
                      <li>
                        Blade Repair: Expertise in rotor blade repair services.
                      </li>
                      <li>
                        Risk Management: Partnerships with insurance companies
                        to provide risk migration and advisory services for
                        renewable assets.
                      </li>
                    </ul>
                    <h4>Intellectual Property</h4>
                    <p>
                      All content, trademarks, logos, and intellectual property
                      displayed on the Website are the property of RENOM or its
                      licensors. Unauthorized use of any material on the Website
                      is strictly prohibited.
                    </p>
                    <h4> Limitation of Liability</h4>
                    <p>RENOM shall not be held liable for:</p>
                    <ul>
                      <li>
                        Any inaccuracies or omissions in the Website’s content.
                      </li>
                      <li>
                        Losses or damages arising from the use of this Website
                        or the information provided herein.
                      </li>
                    </ul>
                    <h4>Third-Party Links</h4>
                    <p>
                      The Website may contain links to third-party websites.
                      These links are provided for your convenience, and RENOM
                      is not responsible for the content or accuracy of any
                      linked websites.
                    </p>
                    <h4>Changes to Terms</h4>
                    <p>
                      RENOM reserves the right to modify these Terms at any
                      time. Changes will be effective immediately upon posting
                      on the Website. Continued use of the Website constitutes
                      acceptance of the updated Terms.
                    </p>
                    <p>
                      For any questions or concerns regarding these Terms,
                      please
                    </p>
                    <h4>Contact us at:</h4>
                    Email:{" "}
                    <a style={{ color: "#00A0A9" }} href="mail:crm.renom.in">
                      crm.renom.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ServicesOilgas;
