import React from "react";
import { NavLink } from "react-router-dom";
import "../../assets/plugins/revolution/revolution-addons/typewriter/css/typewriter.css";
import "../../assets/plugins/revolution/revolution-addons/reveal/css/revolution.addon.revealer.css";
import "../../assets/plugins/revolution/revolution-addons/reveal/css/revolution.addon.revealer.preloaders.css";

class Slider3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/rev-script-3.js");
  }
  render() {
    return (
      <>
        <div
          id="welcome_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="goodnews-header"
          data-source="gallery"
          style={{ background: "#eeeeee", padding: 0 }}
        >
          <div
            id="welcome"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.4.3.1"
          >
            <ul>
              {/* SLIDE 1 */}
              <li
                data-index="rs-901"
                data-transition="fade"
                data-slotamount="default"
                data-hideafterloop={0}
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="media/images/video1.jpg"
                data-rotate={0}
                data-fstransition="fade"
                data-fsmasterspeed={300}
                data-fsslotamount={7}
                data-saveperformance="off"
                data-title="Slide Title"
                data-param1="Additional Text"
                data-param2
                data-param3
                data-param4
                data-param5
                data-param6
                data-param7
                data-param8
                data-param9
                data-param10
                data-description
              >
                {/* MAIN IMAGE */}
                {/* LAYERS */}
                {/* LAYER NR. 1 [ for overlay ] */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-901-layer-0"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 0,
                  }}
                ></div>
                {/* LAYER NR. 2 [ for video ] */}
                <div
                  className="rs-background-video-layer"
                  data-forcerewind="on"
                  data-volume="mute"
                  data-videowidth="100%"
                  data-videoheight="100%"
                  data-videomp4="https://renomwebsite.s3.ap-south-1.amazonaws.com/renom_video.mp4"
                  data-videopreload="auto"
                  data-videoloop="loop"
                  data-aspectratio="16:9"
                  data-autoplay="true"
                  data-autoplayonlyfirsttime="false"
                ></div>
                {/* LAYER NR. 3 [ Black Box ] */}
                <div
                  className="tp-caption   tp-resizeme"
                  id="slide-901-layer-3"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['200','200','200','200']"
                  data-fontsize="['20','20','20','20']"
                  data-lineheight="['56','56','56','50']"
                  data-width="['800','800','600','300']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 13,
                    whiteSpace: "normal",
                    fontWeight: 600,
                    color: "#fff",
                    borderWidth: 0,
                    fontFamily: '"Teko", sans-serif',
                    textTransform: "uppercase",
                  }}
                >
                  <div className="site-text-primary">We Make Sure</div>
                </div>
                {/* LAYER NR. 4 [ for title ] */}
                <div
                  className="tp-caption   tp-resizeme"
                  id="slide-901-layer-4"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['260','260','260','260']"
                  data-fontsize="['70','56','38','28']"
                  data-lineheight="['80','66','48','38']"
                  data-width="['800','800','600','300']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[5,5,5,5]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 13,
                    whiteSpace: "normal",
                    fontWeight: 900,
                    color: "#fff",
                    borderWidth: 0,
                    fontFamily: '"Teko", sans-serif',
                    textTransform: "uppercase",
                  }}
                >
                  We are the best construction company
                </div>
                {/* LAYER NR. 5 [ for paragraph] */}
                <div
                  className="tp-caption  tp-resizeme"
                  id="slide-901-layer-5"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['450','450','380','360']"
                  data-fontsize="['16','16','16','14']"
                  data-lineheight="['30','30','30','22']"
                  data-width="['700','700','600','300']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 13,
                    fontWeight: 500,
                    color: "#fff",
                    borderWidth: 0,
                  }}
                >
                  Industro Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since.
                </div>
                {/* LAYER NR. 6 [ for see all service botton ] */}
                <div
                  className="tp-caption tp-resizeme"
                  id="slide-901-layer-6"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['540','540','470','470']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['800','800','600','300']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{ zIndex: 13, textTransform: "uppercase" }}
                >
                  <NavLink to={"#"} className="site-button site-btn-effect">
                    Read More
                  </NavLink>
                </div>
                {/* Border Part */}
                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-901-layer-8"
                  data-x="['left','left','left','left']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-visibility="['on','on','off','off']"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 10,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "rgb(255,255,255)",
                    borderStyle: "solid",
                    borderWidth: "0px 50px 50px 50px",
                  }}
                />
              </li>
            </ul>
            <div
              className="tp-bannertimer tp-bottom"
              style={{ visibility: "hidden !important" }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Slider3;
