import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import Vas from "../Segments/Vas";
import About5 from "../Segments/About5";
import SelfIntro1 from "../Segments/SelfIntro1";

var bnrimg = require("./../../images/banner/titlebg.jpg");

class VasPage extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Value Added Services"
            pagename="vas"
            bgimage={bnrimg}
          />
          <Vas />
        </div>
        <Footer />
      </>
    );
  }
}

export default VasPage;
