import React from "react";
import { NavLink } from "react-router-dom";

class About2 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-t80 p-b50">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="welcom-to-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>One Team, One Goal, Customer First</div>
                        <div className="sep-leaf-right" />
                      </div>
                    </div>
                    <h2>Know us</h2>
                    {/* TITLE END*/}

                    <p style={{ lineHeight: "1.7" }}>
                      Established in September 2015, RENOM is committed to
                      delivering quality services through innovative practices.
                      As a leading Independent Service Provider (ISP) in the
                      renewable energy sector, RENOM offers premium,
                      cost-effective services for 0.225MW to 2MW turbines across
                      India. With over 1,500 man-years of industry expertise, we
                      empower asset owners with insights and autonomy over their
                      assets. RENOM stands out with its SCADA system, featuring
                      a single-window dashboard for remote turbine monitoring
                      and control, along with an advanced Electronic Repairing
                      Station (ERS) for PCB, Controller, and SRB repairs.
                    </p>
                    <p style={{ lineHeight: "1.7" }}>
                      Our Machine Refurbishment Facility (MRF), crane-less
                      maintenance infrastructure, and blade repair team provide
                      comprehensive asset care. Additionally, RENOM collaborates
                      with insurance companies to offer risk migration and
                      advisory services for renewable assets. Managing over
                      1,000 MW, our footprint spans across all renewable energy
                      states in India.
                    </p>
                    {/* <div className="welcom-to-section-bottom d-flex justify-content-between">
                      <div className="welcom-btn-position">
                        <NavLink
                          to={"/about-1"}
                          className="site-button-secondry site-btn-effect"
                        >
                          More About
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="img-colarge2">
                    {/* <div className="colarge-2 slide-right">
                      <img
                        src={require("./../../images/turbines.jpg")}
                        alt=""
                      />
                    </div> */}
                    <div className="colarge-2-1">
                      <img
                        src={require("./../../images/great_place_to_work.png")}
                        alt=""
                      />
                    </div>
                    {/* <div className="since-year-outer2">
                      <div className="since-year2">
                        <span>Since</span>
                        <strong>2015</strong>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About2;
