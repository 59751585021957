import React from "react";
import Header from "../Parts/Header2";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import About2 from "../Segments/About2";
import Services13 from "../Segments/Services9";
import Statistics11 from "../Segments/Statistics11";
import Team2 from "../Segments/Team2";
import ClientsLogo1 from "../Segments/ClientsLogo1";
import About5 from "../Segments/About5";
import SelfIntro1 from "../Segments/SelfIntro1";

var bnrimg = require("./../../images/banner/titlebg.jpg");

class About1 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="About Us" pagename="About Us" bgimage={bnrimg} />
          <About2 />
          <SelfIntro1 />
          <About5 />
        </div>
        <Footer />
      </>
    );
  }
}

export default About1;
