import React from "react";
import ServicesSidebar from "./ServicesSidebar";

class ServiceDetails6 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full small-device  p-t80 p-b50 bg-white">
          <div className="section-content">
            <div className="container">
              <div className="row">
                <ServicesSidebar />
                <div className="col-lg-8 col-md-12">
                  <div className="section-content">
                    {/* TITLE START */}
                    <div className="text-left">
                      <h2 className="wt-title">
                        Corporate Social Responsibility
                      </h2>
                    </div>
                    {/* TITLE END */}
                    <div className="wt-box services-etc m-b30">
                      <p>
                        CSR at RENOM is routed out through the Sanjay Ghodawat
                        Foundation which is an NGO and carries out various CSR
                        activities Believing firmly in giving back to the
                        society what is earned from it, the philanthropist
                        Sanjay Ghodawat has been donating generously for diverse
                        social causes both personally and through charitable
                        trusts.
                      </p>
                      <p>
                        Sou. Sushila Danchand Ghodawat Charitable Trust provides
                        social, medical and educational help to those who most
                        need it. The ‘no profit, no loss’ Acharya Shri Tulsi
                        Blood Bank has been rated among the best three blood
                        banks in India due to the sheer quality of equipment,
                        infrastructure, and care. The Khivraj Ghodawat
                        Charitable Trust runs a self-funded ‘Shree Gangabai
                        Khivraj Ghodawat Kanya Mahavidyalaya’ in Taluka Shirol.
                      </p>
                      <p>
                        The school has more than 4000 literate girls from 52
                        villages to its credit since 1995. It is also setting up
                        an Intensive Care Unit (ICU) at Jaysingpur by donating
                        medical supplies. Sanjay Ghodawat’s philanthropic
                        leanings have rubbed off on the entire organization.
                        With an unwavering commitment to the society, the Group
                        has to its credit some ground-breaking initiatives
                        including the donation of operation theatres, community
                        halls, school buildings for the blind, etc.
                      </p>
                      <p>
                        The Group’s reforestation drive resulted in the planting
                        of three hundred thousand trees in a single year in 2010
                        and this number continues to grow every year. Major
                        contribution or modest, the Group has always lived up to
                        its social responsibility obligations. So far, the group
                        has planted over 250,000 trees across areas in
                        Maharashtra and Karnataka The foundation also has
                        donated the building and daily management of the Lions
                        Clubs Visually Disabled School in Miraj educating
                        children and helping them become independent in life.
                        The SG Foundation also had set-up 10 bio-toilets in the
                        rural villages around Kolhapur, 6 in Kondigre Village
                        and 4 in Danoli Village
                      </p>

                      {/* <div className="wt-media m-b30">
                        <img
                          src={require("./../../images/services/large/pic1.jpg")}
                          alt=""
                        />
                      </div> */}
                      {/* <div className="wt-info">
                        <blockquote className="m-a0 bg-gray p-a30">
                          <i className="fa fa-quote-left font-20" />
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                            ipsum dolor sit amet, consectetur adipisicing elit,
                          </p>
                          <div className="p-t15 text-uppercase">
                            <strong>William Jomurray</strong>
                            <span>Envato Author</span>
                          </div>
                        </blockquote>
                      </div> */}
                    </div>
                    {/* <div className="service-provide">
                      <h2>System Benefits</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam. Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit.{" "}
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-angle-right">
                            <li>
                              Pitifully thin compared with the size of the rest
                              of him.
                            </li>
                            <li>
                              Raising a heavy muff that covered the whole. muff
                              that covered the whole of her lower arm
                              consectetur.
                            </li>
                            <li>
                              Muff that covered the whole of her lower arm
                              consectetur adipisicing elit
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <div className="wt-media m-b30">
                            <img
                              src={require("./../../images/gallery/pic3.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2>Research</h2>
                      <div className="wt-accordion acc-bg-gray" id="accordion5">
                        <div className="panel wt-panel">
                          <div className="acod-head acc-actives">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseOne5"
                                data-parent="#accordion5"
                              >
                                What problems we face?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseOne5"
                            className="acod-body collapse show bg-gray"
                          >
                            <div className="acod-content p-a15">
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, injected humour, or
                              randomised words which don't look even slightly
                              believablThere are many variations of passages.
                            </div>
                          </div>
                        </div>
                        <div className="panel wt-panel">
                          <div className="acod-head">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseTwo5"
                                className="collapsed"
                                data-parent="#accordion5"
                              >
                                Was our solution best?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseTwo5"
                            className="acod-body collapse bg-gray"
                          >
                            <div className="acod-content p-a15">
                              Graphic design lorem Ipsum is simply dummy text of
                              the printing and type setting industry. Lorem
                              Ipsum has been the industry's standard dummy text
                              ever since the when an unknown printer took.
                            </div>
                          </div>
                        </div>
                        <div className="panel wt-panel">
                          <div className="acod-head">
                            <h4 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseThree5"
                                className="collapsed"
                                data-parent="#accordion5"
                              >
                                Was our client satisfy?
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapseThree5"
                            className="acod-body collapse bg-gray"
                          >
                            <div className="acod-content p-a15">
                              Developement lorem Ipsum is simply dummy text of
                              the printing and type has been the industry's
                              standard dummy text ever since the when an unknown
                              printer took a galley of type and scrambled it to
                              make.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceDetails6;
